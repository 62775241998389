/*.App {*/
/*    font-family: sans-serif;*/
/*}*/

/*ul {*/
/*    margin: 0;*/
/*}*/

/*span {*/
/*    font-size: 2rem;*/
/*    color: #777;*/
/*}*/

.dropzone {
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;

    margin-bottom: 20px;
}

.accept {
    border-color: #107c10 !important;
}

.reject {
    border-color: #d83b01 !important;
}
